<template>
	<!-- Topbar Start -->
	<div class="navbar-custom">
		<ul class="list-unstyled topnav-menu float-right mb-0">
			<b-nav-item-dropdown right class="notification-list text-white">
				<template slot="button-content" class="nav-link dropdown-toggle">
					<!-- <i class="fe-bell noti-icon"></i> -->
					<!-- <i class="fe-bell noti-icon"></i> -->
					<span class="text-white">
						<i class="fas fa-home"></i>
					</span>
				</template>
				<b-dropdown-text href="#" class="dropdown-item noti-title">
					<span>Branch</span>
				</b-dropdown-text>
				<b-dropdown-item v-for="(item, key) in listUserBranch" :key="key" class="dropdown-item active">
					<span v-if="item.id_flexmod === null">
						<a href="javascript:void(0);" :style="
							parseInt(item.branch.id) === parseInt(defaultBranch)
								? 'color:#51C1AD;'
								: 'color:#000 '
						" @click="changeBranch(item.branch.id)">
							{{ item.branch.nama_branch }}
						</a>
					</span>
					<span v-else>
						<a href="javascript:void(0);" :style="
							parseInt(item.is_default) === 1
								? 'color:#51C1AD;'
								: 'color:#000 '
						" @click="changeBranch(item.branch.id)">
							{{ item.branch.nama_branch }}
						</a>
					</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>

			<b-nav-item-dropdown right class="notification-list">
				<template slot="button-content" class="nav-link dropdown-toggle">
					<!-- <i class="fas fa-bell"></i> -->
					<i class="mdi mdi-bell-off"></i>
					<div v-if="listUserNotif.length" class="notify">
						<span class="heartbit"></span> <span class="point"></span>
					</div>
					<!-- <span class="badge badge-danger rounded-circle noti-icon-badge">{{listNotification.length}}</span> -->
				</template>

				<b-dropdown-text href="#" class="dropdown-item noti-title">
					<span>Notificationsi</span>
				</b-dropdown-text>
				<b-dropdown-text href="#" class="p-0">
					<VuePerfectScrollbar class="noti-scroll" style="max-width: 300px; width: 300px" :suppress-scroll-x="false">
						<div v-for="(item, key) in listUserNotif" :key="key" class="table-responsive">
							<a :href="item.url_notification" class="dropdown-item notify-item">
								<div class="notify-icon mr-3">
									<img :src="
										item.logo_user
											? item.logo_user
											: 'https://ui-avatars.com/api/?name=' +
											item.created_name
									" style="
		                      width: 45px;
		                      height: 45px;
		                      border-radius: 8.25rem !important;
		                    " />
								</div>
								<p class="notify-details">
									{{ item.created_name }}
									<small class="text-muted">
										{{ item.msg_notification }}<br />
										{{ item.created_on }}
									</small>
								</p>
							</a>
						</div>
					</VuePerfectScrollbar>

					<a :href="ciUrl + '/admin/notification_list'" class="dropdown-item text-center text-oak notify-item notify-all">
						<u>Check all notification</u>
						<i class="fas fa-chevron-right ml-2"></i>
						<i class="fi-arrow-right"></i>
					</a>
				</b-dropdown-text>
			</b-nav-item-dropdown>

			<b-nav-item-dropdown right class="notification-list" menu-class="profile-dropdown">
				<template slot="button-content">
					<div class="nav-user mr-0">
						<span class="pro-user-name ml-1 mr-2">
							Hi, {{ userName ? userName : 'User' }}
							<!-- <i class="mdi mdi-chevron-down"></i> -->
						</span>
						<!-- <img src="@assets/images/users/user-4.jpg" alt="user-image" class="rounded-circle" /> -->
						<img :src="
							logoUser
								? logoUser
								: 'https://ui-avatars.com/api/?name=' + userName
						" style="border-radius: 8.25rem !important" />
					</div>
				</template>
				<b-dropdown-text class="dropdown-item noti-title">
					<span>Profile</span>
				</b-dropdown-text>
				<b-dropdown-item :href="ciUrl + '/admin/profile'">
					<i class="fas fa-user"></i>
					<span>My Profile</span>
				</b-dropdown-item>

				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item @click="logout">
					<i class="fas fa-sign-in-alt"></i>
					<span>Logout</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</ul>

		<!-- LOGO -->
		<div class="logo-box">
			<router-link tag="a" to="/" class="logo text-center">
				<span class="logo-lg">
					<img src="@assets/images/logo-oak.svg" class="img-fluid" alt style="width: 50px" />
					<!-- <img src="@assets/images/logo-oak.svg" class="img-fluid" alt style="width:50px;" /> -->
					<!-- <span class="logo-lg-text-light">Xeria</span> -->
				</span>
				<span class="logo-sm">
					<!-- <span class="logo-sm-text-dark">X</span> -->
					<img src="@assets/images/logo-oak.svg" class="img-fluid" alt style="width: 50px" />
					<!-- <img src="@assets/images/logo-oak.svg" class="img-fluid" alt style="width:50px;" /> -->
				</span>
			</router-link>
		</div>

		<ul class="list-unstyled topnav-menu topnav-menu-left m-0">
			<li>
				<button class="button-menu-mobile" @click="toggleMenu">
					<i class="fe-menu"></i>
				</button>
			</li>
		</ul>
	</div>
	<!-- end Topbar -->
</template>

<style lang="scss">
.noti-scroll {
	height: 220px;
}

.ps>.ps__scrollbar-y-rail {
	width: 8px !important;
	background-color: transparent !important;
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
	width: 6px !important;
}

.button-menu-mobile {
	outline: none !important;
}
</style>


<script>
import { authComputed } from '@state/helpers'
// import { log } from 'console'
// import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// eslint-disable-next-line no-unused-vars
// import Echo from "laravel-echo";
import { mapState, mapActions } from 'vuex'
import Swal from 'sweetalert2'

// import axios from 'axios'
export default {
	components: {
		VuePerfectScrollbar,
	},
	props: {
		user: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data() {
		return {
			userName: '',
			logoUser: '',
			ciUrl: process.env.VUE_APP_CI_URL,
			placeholder: '@assets/images/icon-user.svg',
			dataBranch: {
				id_flexmod: null
			},
			defaultBranch: localStorage.getItem('default_branch'),
		}
	},
	computed: {
		...authComputed,
		...mapState('userBranch', ['listUserBranch']),
		...mapState('userNotif', ['listUserNotif']),
	},
	created() {
		// this.getListNotification()
		// window.Echo.connector.options.auth.headers["Authorization"] =
		//   "Bearer " + this.$store.state.auth.currentToken.access_token;
		// this.$auth.$storage._state['_token.local']
		// window.Echo.private(
		//   "App.Models.User.User." + this.$store.state.auth.currentUser.id
		// ).notification(notif => {
		//   console.log('mantul mounted')
		//   // console.log(notif);
		//   // this.getListNotification();
		// });
		// window.Echo.connector.options.auth.headers["Authorization"] =
		//   "Bearer " + this.$store.state.auth.currentToken.access_token;
		// // this.$auth.$storage._state['_token.local']
		// window.Echo.private(
		//   "App.User." + this.$store.state.auth.currentUser.id
		// ).notification(notif => {
		//   console.log(notif)
		//   console.log('mantul created')
		//   this.getListNotification();
		// });
		// this.getListNotification()
	},
	mounted() {
		this.get_branch()
		this.getListNotification()

		// console.log(this.$store.state.auth.currentUser.username_user, 'username')
		// var getUser = this.$store.state.auth.currentUser.username_user
		// var getUserr = getUser.split("@");
		this.userName = localStorage.getItem('name')
		this.logoUser = localStorage.getItem('logo')
		this.defaultBranch = localStorage.getItem('default_branch')
		// this.listUserBranch =JSON.parse(localStorage.getItem('list_branch'));
		// this.listUserBranch = localStorage.getItem('list_branch')
		// console.log(this.listUserBranch,'user branhc')

		// console.log(this.defaultBranch, 'default branch')
	},
	methods: {
		// ...mapActions('notification',['getListNotification']),
		...mapActions('userBranch', ['getListUserBranch']),
		...mapActions('userNotif', ['getListUserNotif']),
		...mapActions('branch', ['updateBranch']),

		changeBranch(id) {
			var idUser = this.$store.state.auth.currentUser.id
			this.dataBranch.default_branch = id
			this.dataBranch.id_flexmod = this.$route.query.id_flexmod

			this.updateBranch({
				payload: this.dataBranch,
				id: idUser,
			})
				.then((res) => {
					if (res.result.status === true) {
						console.log(res);
						if (this.$route.query.id_flexmod === undefined || this.$route.query.id_flexmod === null) {
							localStorage.setItem('default_branch', this.dataBranch.default_branch)
						} else {
							localStorage.setItem('default_branch_flexmod', this.dataBranch.id_flexmod)
						}
						location.reload()
					} else {
						Swal.fire({
							title: "Waring !!!",
							text: "It's like you don't have an access for this branch, please contact your administrator to add an access",
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok',
						})
					}
				})
				.catch((err) => {
					console.log(err)
					this.errorAlert('failed update data')
				})
		},
		// get list notif
		getListNotification() {
			this.getListUserNotif()
				.then((res) => {
					// location.reload()
					// console.log(this.listUserNotif, 'list notif mmm')
					// for (let i = 0; i < this.listUserNotif.length; i++) {
					//   const element = this.listUserNotif[i];
					//   axios.get('/notif_logo/' + element.id_user).then((response) => {
					//     console.log(response, 'response logo')
					//   }, () => {
					//     console.log("gagal")
					//   });
					// }
				})
				.catch((err) => {
					console.log(err)
					this.errorAlert()
				})
		},

		// get list branch
		get_branch() {
			this.getListUserBranch(this.$route.query.id_flexmod)
				.then((res) => {
					if (res !== 'revoke') {
						console.log(this.$route.query.id_flexmod)
						console.log(this.listUserBranch)
					} else {
						window.location.href = '/check_logout'
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						window.location.href = '/check_logout'
					}
					console.log(err)
					this.errorAlert()
				})
		},

		toggleMenu() {
			this.$parent.toggleMenu()
		},
		logout() {
			// console.log('ini lgoou')
			window.localStorage.removeItem('access_token')
			window.localStorage.removeItem('userPermissions')
			window.localStorage.removeItem('company')
			window.localStorage.removeItem('user')
			window.localStorage.removeItem('name')
			window.localStorage.removeItem('logo')
			window.localStorage.removeItem('default_branch')
			window.localStorage.removeItem('list_branch')
			window.location.href = this.ciUrl + '/admin/logout_crm'
		},
		// fetchData() {
		//   this.get
		// },
		toggleRightSidebar() {
			this.$parent.toggleRightSidebar()
		},
	},
}
</script>
